import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const TemplateStyle = styled.section`
  .sora {
    font-family: 'Sora';
  }
`

export const Img = styled.img`
  width: 94px;
  height: 94px;
  object-fit: cover;
  border-radius: 50%;
`

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`
export const Paragraph1 = styled.p`
  font-weight: 300;

  @media (min-width: ${breakpoints.md}) {
    font-weight: 600;
  }
`
export const Paragraph2 = styled.p`
  font-weight: 600;

  @media (min-width: ${breakpoints.md}) {
    font-weight: normal;
  }
`
