import React from 'react'

// Components
import Layout from 'src/components/Layout'
import PageTitle from 'src/components/PageTitle'
import ListArticles from 'src/components/ListArticles/ListArticles'
// Data
import colunistasJSON from 'src/assets/data/author.json'
import articlesJSON from 'src/assets/data/articles.json'
// Styles
import SEO from 'src/components/Seo'

import { TemplateStyle, Img, TextGroup, Paragraph1, Paragraph2 } from './style'

type Articles = {
  title: string
  published: string
  readingTime: string
  image: string
  url: string
}

type Columnist = {
  name: string
  image: {
    url: string
    title: string
    alt: string
  }
  description: string
  articles: Articles
}

type ColumnistPageProps = {
  id: string
  slug: string
}

type ColunistaProps = {
  pageContext: {
    page: ColumnistPageProps
    columnist: Columnist
  }
}

const ColumnistTemplate = ({ pageContext }: ColunistaProps) => {
  const data = colunistasJSON.find((colunista) => colunista.id === pageContext.columnist.id)
  const articles = Object.values(articlesJSON).reduce((all, item) => [...all, ...item], [])
  const articlesFiltred = articles.filter(
    (item) => item.authorId && item.authorId.toString() === pageContext.columnist.id,
  )
  const breadcrumbPath = {
    path: [
      {
        text: 'Home',
        link: '/',
      },
      {
        text: 'De Investidor para Investidor',
        link: '/de-investidor-para-investidor',
      },
      {
        text: `${data?.name}`,
        link: `/de-investidor-para-investidor/${data?.slug}`,
      },
    ],
  }

  return (
    <Layout>
      <TemplateStyle>
        <SEO
          title="Colunistas"
          url={`${process.env.BASE_URL}/${data?.slug}`}
          meta={[
            {
              name: 'description',
              content: 'Sobre o colunista',
            },
            {
              property: 'og:title',
              content: `${data?.name} · Inter Invest`,
            },
            {
              property: 'og:description',
              content: data?.description,
            },
            {
              property: 'og:locale',
              content: 'pt_BR',
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              property: 'og:url',
              content: `${process.env.BASE_URL}/${data?.slug}`,
            },
            {
              property: 'og:site_name',
              content: 'Banco Inter',
            },
            {
              property: 'twitter:site',
              content: '@bancointer',
            },
            {
              property: 'twitter:creator',
              content: '@bancointer',
            },
          ]}
        />
        <div className="container">
          <div className="row">
            <PageTitle
              breadcrumb={breadcrumbPath}
              pageTitle={data?.name ? data.name : ''}
              hasLine
            />
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="row text-center text-md-left">
                {data?.image && data.image.url && (
                  <div className="col-12 col-md-2 mb-4">
                    <Img
                      loading="lazy"
                      src={data?.image ? data.image.url : ''}
                      alt={`${data?.image.alt}`}
                    />
                  </div>
                )}
                <div className="col-12 col-md-10">
                  <TextGroup>
                    <Paragraph1
                      className="fs-16 lh-19 fs-md-18 lh-md-25 text-grayscale--500 sora order-last order-md-first"
                      dangerouslySetInnerHTML={{
                        __html: data?.description ? data?.description : '',
                      }}
                    />
                    <Paragraph2
                      className="fs-16 lh-19 fs-md-18 lh-md-25 text-grayscale--400 sora order-first order-md-last"
                      dangerouslySetInnerHTML={{
                        __html: data?.name ? data?.name : '',
                      }}
                    />
                  </TextGroup>
                </div>
              </div>
              <hr style={{ borderStyle: 'dashed', marginBottom: 20 }} />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 pb-5">
              <ListArticles articles={articlesFiltred} />
            </div>
          </div>
        </div>
      </TemplateStyle>
    </Layout>
  )
}

export default ColumnistTemplate
